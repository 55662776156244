import { DocumentSnapshot, DocumentData } from "firebase/firestore";
import { BetEntryI, EventAnswerI, EventI } from "../types/types";

export const handleError = (message: string) => {
  alert(message);
};

export const eventTypeConverter = {
  toFirestore: (data: EventI) => data,
  fromFirestore: (doc: DocumentSnapshot<DocumentData>) => doc.data() as EventI,
};

export const answerTypeConverter = {
  toFirestore: (data: EventAnswerI) => data,
  fromFirestore: (doc: DocumentSnapshot<DocumentData>) =>
    doc.data() as EventAnswerI,
};

export const betTypeConverter = {
  toFirestore: (data: BetEntryI) => data,
  fromFirestore: (doc: DocumentSnapshot<DocumentData>) =>
    doc.data() as BetEntryI,
};
