import { HTMLInputTypeAttribute, forwardRef } from "react";

interface InputProps {
  value?: string;
  defaultValue?: string;
  placeholder?: string;
  customStyles?: string;
  type?: HTMLInputTypeAttribute;
  handleInputChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  labelText?: string;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      value = "",
      defaultValue,
      placeholder,
      customStyles,
      type = "text",
      handleInputChange,
      labelText,
    },
    ref
  ) => {
    return (
      <div className="flex flex-col w-full">
        {labelText && <h5 className="text-sm mb-1">{labelText}</h5>}
        {ref ? (
          <input
            className={`input input-bordered w-full ${customStyles}`}
            ref={ref}
            placeholder={placeholder}
            type={type}
            defaultValue={defaultValue}
            onChange={handleInputChange}
          />
        ) : (
          <input
            className={`input input-bordered w-full ${customStyles}`}
            ref={ref}
            placeholder={placeholder}
            type={type}
            value={value}
            onChange={handleInputChange}
          />
        )}
      </div>
    );
  }
);

export default Input;
