import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { db, firestoreDb } from "../../../firebase/db";
import Input from "../../../components/Input";
import { BetItemI, BetItemType, EventI } from "../../../types/types";
import { extractUsername } from "../../../utils/venmo";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";

const createNewWagerItem = (): BetItemI => {
  return {
    id: Math.floor(Math.random() * 10000000000).toString(),
    title: "",
    type: "yesno",
    options: [],
  };
};

const AdminEvent = () => {
  const params = useParams();
  let navigate = useNavigate();
  let eventId = params.eventId;
  const [loading, setLoading] = useState(false);
  const [storedEvent, setStoredEvent] = useState<EventI>();
  const [name, setName] = useState("");
  const [datetime, setDatetime] = useState("");
  const [welcomeMsg, setWelcomeMsg] = useState("");
  const [venmoUsername, setVenmoUsername] = useState("");
  const [winningsSplitPercent, setWinningsSplit] = useState(0);
  const [betItems, setBetItems] = useState<Array<BetItemI>>([]);
  const [totalEntries, setTotalEntries] = useState(0);
  const [notAllowedEntries, setNotAllowedEntries] = useState(0);
  const [totalPot, setTotalPot] = useState(0);

  useEffect(() => {
    const getEvent = async () => {
      if (eventId) {
        const event = await db.events.get(eventId);
        if (event) {
          setStoredEvent(event);
          setName(event.eventName);
          setDatetime(event.datetime);
          const items = event.betItems.filter((item) => item !== null);
          //@ts-ignore
          setBetItems(items);
          setWelcomeMsg(event.welcomeMsg || "");
          setVenmoUsername(event.venmoUsername || "");
          setWinningsSplit(event.winningsSplitPercent);
        }
      }
    };

    const getBets = async () => {
      if (eventId) {
        let entries = 0;
        let notAllowed = 0;
        let pot = 0;
        const q = query(
          collection(firestoreDb, "bets"),
          where("eventId", "==", eventId),
          orderBy("numOfCorrectAnswers", "desc"),
          orderBy("createdAt", "desc")
        );
        const bets = await getDocs(q);
        bets.forEach((bet) => {
          const data = bet.data();

          entries += 1;
          if (data.eventBlackList) {
            notAllowed += 1;
          }

          if (data.betAmount) {
            pot += Number(data.betAmount);
          }
        });

        setTotalEntries(entries);
        setNotAllowedEntries(notAllowed);
        setTotalPot(pot);
      }
    };

    getEvent();
    getBets();
  }, [eventId, loading]);

  const updateBetItem = (index: number, newItem: BetItemI) => {
    // shallow copy of old items
    const items = [...betItems];
    // update by index
    items[index] = newItem;
    // save
    setBetItems(items);
  };

  const addOption = (index: number) => {
    // shallow copy of old items
    const items = [...betItems];
    // update by index
    items[index].options?.push("");
    // save
    setBetItems(items);
  };

  const saveEvent = async () => {
    if (eventId) {
      setLoading(true);
      try {
        const parsedVenmoUsername = extractUsername(venmoUsername);
        if (storedEvent?.paymentType === "venmo") {
          const parsedVenmoUsername = extractUsername(venmoUsername);
          if (!parsedVenmoUsername) {
            alert("Venmo username is required");
            return;
          }
        }
        await db.events.update(eventId, {
          eventName: name,
          datetime,
          betItems,
          welcomeMsg,
          venmoUsername: parsedVenmoUsername,
          winningsSplitPercent,
        });
      } catch (error) {
        alert("Unable to save event. Please contact our support team.");
      }

      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center w-full mt-7">
      <div className="w-full max-w-sm">
        <h1 className="text-2xl">Admin Event</h1>
        <div>
          <div className="mt-2 mb-2">
            <p
              className="link link-hover link-primary"
              onClick={() => navigate(`answers`)}
            >
              Wedding live? Go to answer questions
            </p>
          </div>
          <div className="mt-2 mb-4">
            <h2 className="text-lg mb-2">Stats</h2>
            <p>#️⃣ Total entries: {totalEntries} </p>
            <p>⛔ Not allowed entries: {notAllowedEntries} </p>
            <p>💰 Total pot: ${totalPot}</p>
          </div>
          <div className="flex flex-col">
            <h2 className="text-lg mb-2">Event Details</h2>
            <input
              className="input input-bordered mb-3"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <Input
              value={datetime}
              type="datetime-local"
              customStyles="mb-2"
              handleInputChange={(e) => setDatetime(e.target.value)}
            />
            <textarea
              className="input input-bordered mb-3 h-60"
              value={welcomeMsg}
              onChange={(e) => setWelcomeMsg(e.target.value)}
            />
            {storedEvent?.paymentType === "venmo" && (
              <div className="w-full">
                <h3 className="text-lg">Venmo Username</h3>
                <p>This is where people will send their bet amounts.</p>
                <input
                  className="input input-bordered mb-3 w-full"
                  value={venmoUsername}
                  onChange={(e) => setVenmoUsername(e.target.value)}
                />
              </div>
            )}
            <h3 className="text-lg">% to the Winner</h3>
            <input
              className="input input-bordered mb-3"
              value={winningsSplitPercent}
              onChange={(e) => setWinningsSplit(Number(e.target.value))}
              min={0}
            />
          </div>

          <div className="flex flex-col mt-5">
            <h2 className="text-lg self-start">Wager Items</h2>
            <p className="text-md self-start">
              These are the questions your guests will answer.
            </p>
            {betItems &&
              betItems.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="border-2 border-accent rounded-md p-3 mt-3"
                  >
                    <h5 className="text-sm mb-1">Title</h5>
                    <input
                      className="mb-2 w-full input input-bordered"
                      defaultValue={item.title}
                      onChange={(e) =>
                        updateBetItem(index, {
                          ...item,
                          title: e.target.value,
                        })
                      }
                    />
                    <h5 className="text-sm mb-1">Type</h5>
                    <select
                      className="select select-bordered w-full"
                      value={item.type}
                      onChange={(e) =>
                        updateBetItem(index, {
                          ...item,
                          type: e.target.value as BetItemType,
                        })
                      }
                    >
                      <option disabled value="default">
                        Select Type
                      </option>
                      <option value="text">Text</option>
                      <option value="yesno">Yes / No</option>
                      {/* <option value="number">Number</option> */}
                      <option value="dropdown">Options</option>
                    </select>
                    {item.type === "dropdown" ? (
                      <div className="mt-5">
                        <h3 className="text-sm">Options to Pick From</h3>
                        <div className="flex flex-col">
                          {item.options?.map((value, optionIndex) => (
                            <div
                              key={optionIndex}
                              className="flex flex-row mt-3"
                            >
                              <input
                                className="input input-bordered"
                                value={value}
                                onChange={(e) => {
                                  if (item.options) {
                                    item.options[optionIndex] = e.target.value;
                                  }
                                  updateBetItem(index, {
                                    ...item,
                                  });
                                }}
                                placeholder="Add option"
                              />
                              <button
                                className="bg-error ml-3 pl-3 pr-3 rounded"
                                onClick={() => {
                                  item.options?.splice(optionIndex, 1);
                                  updateBetItem(index, {
                                    ...item,
                                  });
                                }}
                              >
                                X
                              </button>
                            </div>
                          ))}
                        </div>
                        <button
                          className="btn btn-ghost p-0"
                          onClick={(e) => addOption(index)}
                        >
                          Add
                        </button>
                      </div>
                    ) : null}
                  </div>
                );
              })}
            <button
              className="btn self-start mt-3"
              onClick={() => setBetItems([...betItems, createNewWagerItem()])}
            >
              Add Bet Item
            </button>
          </div>
          <div className="mt-5 mb-20">
            <button className="btn btn-primary w-full" onClick={saveEvent}>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminEvent;
