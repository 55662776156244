import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  collection,
  query,
  where,
  onSnapshot,
  Unsubscribe,
  orderBy,
} from "firebase/firestore";
import { db, firestoreDb } from "../../../firebase/db";
import { BetEntryI, EventI } from "../../../types/types";

const EventLeaderboard = () => {
  const params = useParams();
  const eventId = params.eventId;
  const [lastUpdated, setLastUpdated] = useState<Date>();
  const [betData, setBetData] = useState<Array<BetEntryI>>([]);
  const [event, setEvent] = useState<EventI>();

  useEffect(() => {
    let unsubscribe: Unsubscribe;
    const getEvent = async () => {
      if (eventId && !event) {
        const data = await db.events.get(eventId);
        if (data) {
          setEvent(data);
        }
      }
    };

    if (event) {
      if (!event?.paymentType) {
        return;
      }

      const q = query(
        collection(firestoreDb, "bets"),
        where("eventId", "==", event.id),
        orderBy("numOfCorrectAnswers", "desc"),
        orderBy("createdAt", "desc")
      );
      unsubscribe = onSnapshot(q, (querySnapshot) => {
        const bets: Array<BetEntryI> = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data() as BetEntryI;
          if (event.paymentType === "venmo") {
            // we don't know if they completed the payment
            // so by default we need to show everyone
            // this is unfortunately a gap w/ the Venmo option for now
            if (!data.eventBlackList) {
              bets.push(data);
            }
          } else {
            // only include bets that have a successful payment
            if (data.paymentEventId && !data.eventBlackList) {
              bets.push(data);
            }
          }
        });

        setBetData(bets);
        setLastUpdated(new Date());
      });
    }

    getEvent();

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [eventId, event]);

  if (event) {
    return (
      <div className="h-full p-5 w-full flex flex-col items-center">
        <div className="w-full max-w-md">
          <h2 className="text-3xl mb-3 font-medium">{event.eventName}</h2>
          <h4 className="text-2xl mb-1">Leaderboard</h4>
          <p className="text-sm mb-5">
            Last updated: {lastUpdated?.toLocaleString()}
          </p>
          <div className="flex flex-row mb-3">
            <h3 className="flex-1 text-xl">Name</h3>
            <h3 className="flex-1 text-xl"># Correct</h3>
            <h3 className="flex-1 text-xl">% Correct</h3>
          </div>
          <div className="flex flex-col gap-1">
            {betData.map((item, index) => {
              const percentCorrect = (
                (item.numOfCorrectAnswers / event.betItems.length) *
                100
              ).toFixed(1);
              return (
                <div
                  key={index}
                  className="flex flex-row border-b-[1px] border-gray-200"
                >
                  <div className="flex-1">
                    <p>
                      {item.firstName} {item.lastName}
                    </p>
                  </div>
                  <div className="flex-1 self-center justify-center text-center">
                    <p>{item.numOfCorrectAnswers}</p>
                  </div>
                  <div className="flex-1 self-center justify-center text-center">
                    <p>{percentCorrect}%</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="h-full p-5 w-full flex flex-col items-center">
      <div className="w-full max-w-md">
        <p className="text-lg">Loading leaderboard...</p>
      </div>
    </div>
  );
};

export default EventLeaderboard;
