import { useState, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { getFunctions, httpsCallable } from "firebase/functions";
import { PublicEventContextI } from "../../../../types/types";
import { db } from "../../../../firebase/db";
import { BetSlipItemI } from "../../../../types/types";
import BetSlipItem from "./BetSlipItem";
import BetAmounts from "./BetAmounts";
import "./styles.css";
import BackBtn from "../../../../components/BackBtn";
import { generateRandomCode } from "../../../../utils/generate-random-code";
import { generateVenmoURL } from "../../../../utils/venmo";

const BetSlipEntry = () => {
  const {
    event,
    setStep,
    personalInfo,
    betAmount,
    setBetShortId,
    setVenmoRedirectUrl,
  } = useOutletContext<PublicEventContextI>();
  const [betIndex, setBetIndex] = useState(0);
  const [bets, setBets] = useState<Array<BetSlipItemI>>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const previousQuestion = () => {
    if (betIndex > 0) {
      setBetIndex(betIndex - 1);
    }
  };

  const nextQuestion = () => {
    setBetIndex(betIndex + 1);
  };

  const updateBetItem = (itemId: string, answer: any) => {
    let updatedExistingItem = false;

    const newItem: BetSlipItemI = {
      betItemId: itemId,
      answer: answer,
      correct: false,
    };

    const updatedBets = bets.map((currentItem) => {
      if (currentItem.betItemId === newItem.betItemId) {
        updatedExistingItem = true;
        currentItem.answer = answer;
      }
      return currentItem;
    });

    if (updatedExistingItem) {
      // update
      setBets(updatedBets);
    } else {
      // add
      setBets([...bets, newItem]);
    }
    nextQuestion();
  };

  const submitBet = async () => {
    setIsLoading(true);

    const shortId = generateRandomCode(8);
    const venmoUrl = generateVenmoURL(
      event.venmoUsername as string,
      betAmount,
      "The Wedding Game! See you soon!"
    );
    // create bet record
    const bet = await db.bets.create({
      eventId: event.id as string,
      shortId: shortId,
      firstName: personalInfo.firstName,
      lastName: personalInfo.lastName,
      phoneNumber: personalInfo.phoneNumber,
      betAmount: betAmount,
      betAnswers: bets,
      paymentType: event.paymentType,
      venmoRedirectUrl: venmoUrl,
    });
    setBetShortId(shortId);
    setVenmoRedirectUrl(venmoUrl);

    if (bet?.id) {
      /* Handle the different payment types */
      const functions = getFunctions();

      if (event.paymentType === "stripe") {
        const createStripeCheckoutSession = httpsCallable(
          functions,
          "createStripeCheckoutSession"
        );
        const session = await createStripeCheckoutSession({
          betId: bet.id,
          betAmount: betAmount,
          eventId: event.id,
        });

        if (session.data) {
          const stripeSessionData = session.data as any;
          window.location.href = stripeSessionData.url;
        } else {
          alert("Unable create payment session.");
        }
      }

      if (event.paymentType === "venmo") {
        try {
          const sendTextMessage = httpsCallable(functions, "sendTextMessage");
          await sendTextMessage({
            phoneNum: personalInfo.phoneNumber,
            message: `Hey! The Wedding Game here. Looks like you placed a big time wager for ${event.eventName}. Nice work! If you haven't sent your Venmo yet, please click here: ${venmoUrl}\n\nHere's the leaderboard. ${process.env.REACT_APP_WW_DOMAIN}/event/${event.id}/leaderboard `,
          });
        } catch (error) {
          // handle error
          console.log(error);
        }
        setStep("betSlipVenomPayment");
      }
    } else {
      alert("Unable to create bet.");
      // TODO - notify us
    }
    setIsLoading(false);
  };

  const stillBetting = betIndex < event.betItems.length;

  return (
    <div className="p-8 flex flex-col bg-white h-full w-full max-w-sm">
      {stillBetting ? (
        <div className="self-center items-center text-center h-20 text-primary">
          <h5 className="text-sm">QUESTION</h5>
          <h5 className="text-2xl">
            {betIndex + 1} of {event.betItems.length}
          </h5>
        </div>
      ) : null}
      <div className="flex-1 h-full flex-grow w-full">
        {stillBetting ? (
          <BetSlipItem
            betIndex={betIndex}
            item={event.betItems[betIndex]}
            previousQuestion={previousQuestion}
            updateBetItem={updateBetItem}
          />
        ) : (
          <CSSTransition in={!stillBetting} timeout={500} classNames="niceWork">
            <div>
              <BackBtn handleClick={() => setBetIndex(betIndex - 1)} />
              <h4 className="text-5xl pb-2">Nice work!</h4>
              <div className="text-lg">
                <p>
                  We're super excited to have you join us on our big day and
                  hope this adds a little fun!
                </p>
                <br />
                <p>Feeling confident? Update your wager before placing it.</p>
              </div>
              <BetAmounts />
              <button
                className="btn btn-primary w-full"
                onClick={submitBet}
                disabled={isLoading}
              >
                {isLoading && (
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                )}
                Place Bet
              </button>
            </div>
          </CSSTransition>
        )}
      </div>
    </div>
  );
};

export default BetSlipEntry;
