import { useOutletContext } from "react-router-dom";
import { PublicEventContextI } from "../../../../types/types";
import Logo from "../../../../Logo.png";

const Welcome = () => {
  const { event, setStep } = useOutletContext<PublicEventContextI>();
  return (
    <div className="h-full flex flex-col flex-grow items-center max-w-sm mb-12 p-4">
      <img src={Logo} alt="The Wedding Game logo" width={200} />
      <h3 className="text-lg mb-2">
        {new Date(event.datetime).toLocaleDateString()}
      </h3>
      <h3 className="text-3xl">{event.eventName}</h3>
      <div className="mt-5 mb-5">
        <h2 className="text-2xl mb-2">What is this?</h2>
        <div className="space-y-3">
          <p>
            The Wedding Game is exactly as it sounds. It's a fun game where
            wedding guests can guess what is going to happen on the big day.
            Think: Will the groom cry? What will the first dance song be?
          </p>
          <p>
            Except, it's even more fun than that.{" "}
            <span className="font-bold">Each guest places a wager </span>
            where they pick the amount 💰 they want to contribute to the happy
            couple (e.g. honeymoon fund).
          </p>
          <p>
            The absolute best part of this?{" "}
            <span className="font-bold">
              You potentially walk away with some big winnings if you guess the
              most correct!
            </span>{" "}
            Plus, everyone has fun. That's a win-win-win.
          </p>
        </div>
      </div>
      <button
        className="btn btn-primary mt-8 mb-12"
        onClick={() => setStep("howItWorks")}
      >
        Let's go (takes 2 mins)
      </button>
    </div>
  );
};

export default Welcome;
