import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFunctions } from "firebase/functions";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBsEUcxu3isR-vU7Ohf9lTOvW2oNYjwhBk",
  authDomain: "weddingbet-xoxo.firebaseapp.com",
  projectId: "weddingbet-xoxo",
  storageBucket: "weddingbet-xoxo.appspot.com",
  messagingSenderId: "821660616194",
  appId: "1:821660616194:web:bf44701512f170c515491b",
  measurementId: "G-VW4TLFWJQL",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);
const functions = getFunctions(app);

export { app, functions };
