import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../../firebase/db";
import { BetItemI } from "../../../types/types";
import BetSlipItem from "../../Public/PublicEvent/BetSlipEntry/BetSlipItem";

const AdminEventAnswers = () => {
  const params = useParams();
  let eventId = params.eventId;
  const [name, setName] = useState("");
  const [betItems, setBetItems] = useState<Array<BetItemI>>([]);

  useEffect(() => {
    const getEvent = async () => {
      if (eventId) {
        const event = await db.events.get(eventId);
        if (event) {
          setName(event.eventName);
          const items = event.betItems.filter((item) => item !== null);
          //@ts-ignore
          setBetItems(items);
        }
      }
    };

    getEvent();
  }, [eventId]);

  const handleUpdateAnswer = async (
    betItemId: string,
    answer: any,
    answerDbId?: string
  ) => {
    if (eventId) {
      const successful = await db.answers.upsert(
        eventId,
        betItemId,
        answer,
        answerDbId
      );
      if (successful) {
        alert("Answer updated");
      }
    }
  };

  return (
    <div className="flex flex-col w-full justify-center items-center">
      <div className="w-3/4 max-w-md mt-7">
        <h1 className="text-2xl">{name}</h1>
        <p className="mb-3">Fill out the event answers</p>
        <div>
          {betItems.map((item, index) => {
            return (
              <BetSlipItem
                key={index}
                item={item}
                betIndex={index}
                previousQuestion={() => console.log("this does nothing")} // we don't need this for the admin view
                updateBetItem={handleUpdateAnswer}
                type="admin"
                isAdminView={true}
                eventId={eventId}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default AdminEventAnswers;
