import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import "./firebase/config";
import App from "./App";
import AdminRoute from "./views/Admin";
import AdminCreateEvent from "./views/Admin/AdminCreateEvent";
import AdminEvent from "./views/Admin/AdminEvent";
import AdminEventAnswers from "./views/Admin/AdminEventAnswers";
import PublicRoute from "./views/Public";
import PublicEvent from "./views/Public/PublicEvent";
import PaymentSuccess from "./views/Public/PaymentSuccess";
import EventLeaderboard from "./views/Public/EventLeaderboard";
import VenmoRedirect from "./views/Public/PublicEvent/VenmoRedirect";
import VenmoPayment from "./views/Public/PublicEvent/BetSlipVenmoPayment";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/admin" element={<AdminRoute />}>
          <Route path="create-event" element={<AdminCreateEvent />} />
          <Route path="event/:eventId" element={<AdminEvent />} />
          <Route
            path="event/:eventId/answers"
            element={<AdminEventAnswers />}
          />
        </Route>
        <Route path="/event" element={<PublicRoute />}>
          <Route path=":eventId" element={<PublicEvent />} />
          <Route path=":eventId/venmo" element={<VenmoPayment />} />
          <Route
            path=":eventId/redirect/:betShortId"
            element={<VenmoRedirect />}
          />
          <Route path=":eventId/leaderboard" element={<EventLeaderboard />} />
          <Route path="bet/:betId" element={<PublicEvent />} />
          <Route
            path="bet/payment-success/:betId/:eventId"
            element={<PaymentSuccess />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
