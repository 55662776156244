import { useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import { PublicEventContextI } from "../../../../types/types";
import VenmoLogo from "../../../../assets/venmo-logo.png";
import { isMobile } from "../../../../utils/ui";
import QRCode from "qrcode.react";

const VenmoPayment = () => {
  const { venmoRedirectUrl } = useOutletContext<PublicEventContextI>();
  const isOnMobile = isMobile();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const handleRedirect = () => {
    window.location.href = venmoRedirectUrl;
  };

  return (
    <div className="flex flex-col p-8 h-full max-w-sm">
      <h1 className="text-4xl mb-2">Complete Payment</h1>
      <p>
        Finalize your wager / honeymoon contribution with{" "}
        <img src={VenmoLogo} alt="Venmo logo" className="h-3 inline-block" />.
      </p>
      {isOnMobile ? (
        <div className="mt-2 font-bold">
          <p>
            The amount and the recipient will be pre-populated for you. Click
            the button below to finalize your contribution.
          </p>
          <button
            className="btn btn-primary self-center w-2/3 mt-5"
            onClick={handleRedirect}
          >
            Take Me to Venmo
          </button>
        </div>
      ) : (
        <div className="mt-4">
          <p className="mb-4 font-bold">
            The amount and the recipient will be pre-populated for you. Scan
            this code with your phone to go to Venmo.
          </p>
          <QRCode value={venmoRedirectUrl} size={200} />
        </div>
      )}
      <div className="mt-8">
        <p>Don't have a Venmo account?</p>
        <ol>
          <li>
            1. Search your Apple or Google Play store for the Venmo app and
            install
          </li>
          <li>
            2.{" "}
            {isOnMobile ? (
              <span>
                Click{" "}
                <span
                  className="link link-hover link-primary"
                  onClick={handleRedirect}
                >
                  this link
                </span>{" "}
                to finalize your contribution
              </span>
            ) : (
              <span>Scan the QR code above to finalize your contribution</span>
            )}
          </li>
        </ol>
      </div>
    </div>
  );
};

export default VenmoPayment;
