import { Link, useParams } from "react-router-dom";

const PaymentSuccess = () => {
  const params = useParams();
  const eventId = params.eventId;

  return (
    <div className="flex flex-col items-center h-full w-full">
      <div className="p-5 rounded-lg h-4/6 max-w-sm">
        <h2 className="text-4xl mb-5 text-secondary font-bold">
          Your wager has been placed!
        </h2>
        <div className="text-lg space-y-4">
          <p className="">
            Thanks so much. We're excited to have you be part of our wedding.
            Best of luck!
          </p>
          <p>
            You should receive a text message with a link to the leaderboard so
            you get live updates throughout the wedding.
          </p>
          <p>
            You can also{" "}
            <Link
              to={`/p/event/${eventId}/leaderboard`}
              className="text-blue-500"
            >
              view the leaderboard here.
            </Link>
          </p>
          <div className="space-y-0 font-bold">
            <p>Love,</p>
            <p>Ryan & Amanda</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccess;
