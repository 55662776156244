export const generateVenmoURL = (
  username: string,
  amount: string,
  note = ""
) => {
  const baseUrl = "venmo://paycharge";
  const encodedNote = encodeURIComponent(note);

  const params = new URLSearchParams({
    txn: "pay",
    recipients: username,
    amount: amount,
    note: encodedNote,
  });

  return `${baseUrl}?${params.toString()}`;
};

export function extractUsername(input: string) {
  const regex = /@?(\w+(?:-\w+)*)/;
  const match = input.match(regex);
  return match ? match[1] : null;
}
