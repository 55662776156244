import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../../../firebase/db";
import { EVENT } from "../../../constants/routes";
import { extractUsername } from "../../../utils/venmo";

const CreateEvent = () => {
  const nameRef = useRef<HTMLInputElement>(null);
  const dateRef = useRef<HTMLInputElement>(null);
  const welcomeMsgRef = useRef<HTMLTextAreaElement>(null);
  const venmoUsernameRef = useRef<HTMLInputElement>(null);
  const winningsSplitPercentRef = useRef<HTMLInputElement>(null);

  let navigate = useNavigate();

  const handleCreate = async () => {
    const name = nameRef?.current?.value;
    const datetime = dateRef?.current?.value;
    const welcomeMsg = welcomeMsgRef?.current?.value;
    let venmoUsername = venmoUsernameRef?.current?.value || null;
    let winningsSplitPercent = winningsSplitPercentRef?.current?.value;

    if (
      !name ||
      !datetime ||
      !welcomeMsg ||
      !venmoUsername ||
      !winningsSplitPercent
    ) {
      alert("Missing required fields. Please fill in all of them.");
    } else {
      venmoUsername = extractUsername(venmoUsername);
      if (!venmoUsername) {
        alert("A valid Venmo username is required.");
        return;
      }

      if (Number(winningsSplitPercent) < 0) {
        alert("Winning split cannot be negative");
        return;
      }
      const doc = await db.events.create(
        name,
        datetime,
        welcomeMsg,
        venmoUsername,
        Number(winningsSplitPercent)
      );
      if (doc) {
        navigate(`../${EVENT}/${doc.id}`);
      }
    }
  };

  return (
    <div className="w-full flex flex-col items-center mt-7">
      <div className="w-full max-w-sm">
        <h2 className="text-2xl mb-5">Create New Event</h2>
        <div className="w-3/4 flex flex-col">
          <h3 className="text-lg">Your Wedding Name</h3>
          <p className="text-sm">Example: Billy & Katy's Wedding</p>
          <input
            className="input input-bordered w-full mb-5 mt-3"
            ref={nameRef}
          />
          <h3 className="text-lg">When's the Wedding?</h3>
          <input
            className="input input-bordered w-full mb-5 mt-3"
            type="datetime-local"
            ref={dateRef}
          />
          <h3 className="text-lg">Welcome Message</h3>
          <textarea
            className="input input-bordered w-full mb-5 mt-3 h-80"
            ref={welcomeMsgRef}
            placeholder="Hey! Ryan & Amanda here :) We'd love for you to have a little fun & guess what's going to happen at our wedding! You'll be competing against the fellow attendees so the competition is fierce. To play, donate a small amount to the honeymoon fund and have the chance to win 50% of the total pot."
          />
          {/* THIS SHOULD BE DEPENDENT ON PAYMENT TYPE ONCE WE SUPPORT MULTIPLE TYPES */}
          <h3 className="text-lg">Venmo Username</h3>
          <p>This is where people will send their bet amounts.</p>
          <input
            className="input input-bordered w-full mb-5 mt-3"
            ref={venmoUsernameRef}
            placeholder="@your-user-name"
          />
          <h3 className="text-lg">% to the Winner</h3>
          <p>
            To incentize people to participate, we strongly recommend splitting
            the contributions with the winner. Our default is 50%. You can
            adjust if you want.
          </p>
          <div className="flex flex-row items-center gap-2">
            <input
              className="input input-bordered w-full mb-5 mt-3 grow"
              ref={winningsSplitPercentRef}
              type="number"
              placeholder="50"
              defaultValue={50}
              min={0}
            />
          </div>
          <button className="btn btn-primary mt-5 mb-8" onClick={handleCreate}>
            Create
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateEvent;
