import {
  getFirestore,
  collection,
  query,
  where,
  doc,
  addDoc,
  DocumentReference,
  DocumentData,
  getDoc,
  updateDoc,
  UpdateData,
  Timestamp,
  getDocs,
} from "firebase/firestore";
import { app } from "./config";
import { EVENTS, BETS, ANSWERS } from "./constants";
import {
  handleError,
  eventTypeConverter,
  answerTypeConverter,
  betTypeConverter,
} from "../utils/api";
import {
  CreateEventI,
  UpdateEventI,
  EventI,
  BetSlipItemI,
  BetEntryI,
  EventAnswerI,
  EventPaymentType,
} from "../types/types";

// Initialize Cloud Firestore and get a reference to the service
const firestoreDb = getFirestore(app);

const events = {
  create: async (
    eventName: string,
    datetime: string,
    welcomeMsg: string,
    venmoUsername: string,
    winningsSplitPercent: number
  ): Promise<DocumentReference | undefined> => {
    try {
      const data: CreateEventI = {
        createdAt: Timestamp.now(),
        eventName,
        datetime,
        betItems: [],
        welcomeMsg,
        paymentType: "venmo",
        venmoUsername,
        winningsSplitPercent,
      };
      return await addDoc(collection(firestoreDb, EVENTS), data);
    } catch (error) {
      handleError("Unable to create event.");
    }
  },
  get: async (eventId: string) => {
    try {
      const eventRef = doc(firestoreDb, EVENTS, eventId).withConverter(
        eventTypeConverter
      );
      const eventSnap = await getDoc(eventRef);
      if (eventSnap.exists()) {
        return { ...eventSnap.data(), id: eventSnap.id };
      } else {
        handleError("Unable to retrieve data.");
      }
    } catch (error) {
      handleError("Unable to retrieve data.");
    }
  },
  update: async (eventId: string, data: UpdateEventI) => {
    try {
      const eventDoc = doc(firestoreDb, EVENTS, eventId);
      return await updateDoc(eventDoc, data as UpdateData<EventI>);
    } catch (error) {
      handleError("Unable to update event.");
    }
  },
};

const bets = {
  create: async ({
    shortId,
    eventId,
    firstName,
    lastName,
    phoneNumber,
    betAmount,
    betAnswers,
    paymentType,
    venmoRedirectUrl,
  }: {
    shortId: string;
    eventId: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    betAmount: string;
    betAnswers: Array<BetSlipItemI>;
    paymentType: EventPaymentType;
    venmoRedirectUrl?: string;
  }): Promise<DocumentReference | undefined> => {
    try {
      const data: BetEntryI = {
        createdAt: Timestamp.now(),
        shortId,
        eventId,
        firstName,
        lastName,
        phoneNumber,
        betAnswers,
        numOfCorrectAnswers: 0,
        betAmount,
        paymentSuccessful: false,
        paymentType,
        venmoRedirectUrl,
      };
      return await addDoc(collection(firestoreDb, BETS), data);
    } catch (error) {
      handleError("Uh oh. We ran into an issue. Unable to create bet.");
    }
  },
  getByPhoneNumber: async (
    phoneNumber: string,
    eventId: string
  ): Promise<DocumentData | null> => {
    try {
      const q = query(
        collection(firestoreDb, `${BETS}`),
        where("phoneNumber", "==", phoneNumber),
        where("eventId", "==", eventId)
      );
      const docs = await getDocs(q);
      if (docs.size > 0) {
        const doc = docs.docs[0];
        const data = doc.data();
        return data;
      }
      return null;
    } catch (error) {
      handleError("Uh oh. We ran into an issue. Unable to access data.");
      return null;
    }
  },
  getByShortId: async (
    shortId: string,
    eventId: string
  ): Promise<DocumentData | null> => {
    try {
      const q = query(
        collection(firestoreDb, `${BETS}`),
        where("shortId", "==", shortId),
        where("eventId", "==", eventId)
      ).withConverter(betTypeConverter);
      const docs = await getDocs(q);
      if (docs.size > 0) {
        const doc = docs.docs[0];
        const data = doc.data();
        return data;
      }
      return null;
    } catch (error) {
      handleError("Uh oh. We ran into an issue. Unable to access bet.");
      return null;
    }
  },
};

const answers = {
  // use this query to default answer data in BetSlipItem
  getByBetItemId: async (
    eventId: string,
    betItemId: string
  ): Promise<EventAnswerI | null> => {
    const q = query(
      collection(firestoreDb, `${EVENTS}/${eventId}/${ANSWERS}`),
      where("betItemId", "==", betItemId)
    ).withConverter(answerTypeConverter);
    const docs = await getDocs(q);
    if (docs.size > 0) {
      const doc = docs.docs.sort((a, b) => {
        if (a.data().createdAt > b.data().createdAt) {
          return -1;
        }
        if (a.data().createdAt < b.data().createdAt) {
          return 1;
        }
        return 0;
      })[0];
      const data = { ...doc.data(), id: doc.id };
      return data;
    }
    return null;
  },
  upsert: async (
    eventId: string,
    betItemId: string,
    answer: any,
    answerDbId?: string
  ): Promise<boolean> => {
    try {
      const data: EventAnswerI = {
        createdAt: Timestamp.now(),
        eventId,
        betItemId,
        answer,
      };
      if (answerDbId) {
        const docRef = doc(
          firestoreDb,
          `${EVENTS}/${eventId}/${ANSWERS}`,
          answerDbId
        );
        // @ts-ignore
        await updateDoc(docRef, data);
      }
      await addDoc(
        collection(firestoreDb, `${EVENTS}/${eventId}/${ANSWERS}`),
        data
      );
      return true;
    } catch (error) {
      handleError("Unable to update answer.");
      return false;
    }
  },
};

const db = {
  events,
  bets,
  answers,
};

export { db, firestoreDb };
