import { useRef, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import { db } from "../../../../firebase/db";
import { PublicEventContextI } from "../../../../types/types";
import BackBtn from "../../../../components/BackBtn";

const PersonalInfo = () => {
  const { event, setStep, setPersonalInfo } =
    useOutletContext<PublicEventContextI>();

  const firstNameRef = useRef<HTMLInputElement>(null);
  const lastNameRef = useRef<HTMLInputElement>(null);
  const phoneNumRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const handleValidations = async (phoneNumber: string) => {
    const eventStartingTime = new Date(event.datetime);
    const now = new Date();
    if (now > eventStartingTime) {
      alert(
        "Oh no. The event has already started. No more bets can be placed."
      );
      return false;
    }

    if (process.env.NODE_ENV === "production") {
      const betIfItExists = await db.bets.getByPhoneNumber(
        phoneNumber,
        event.id
      );
      if (betIfItExists) {
        alert("You've already placed a bet. Only one is allowed per event.");
        return false;
      }
    }
    return true;
  };

  const handleSubmit = async () => {
    const firstName = firstNameRef.current?.value;
    const lastName = lastNameRef.current?.value;
    let phoneNumber = phoneNumRef.current?.value;

    if (firstName && lastName && phoneNumber) {
      phoneNumber = phoneNumber.replace(/\D/g, "");

      // run through validations
      const isValid = await handleValidations(phoneNumber);
      if (isValid) {
        setPersonalInfo({
          firstName,
          lastName,
          phoneNumber,
        });
        setStep("betSlipEntry");
      }
    } else {
      alert("Please fill in required fields.");
    }
  };

  return (
    <div className="flex flex-col w-full p-8 max-w-sm">
      <BackBtn handleClick={() => setStep("betAmount")} />
      <h1 className="text-4xl">Your Info</h1>
      <div className="flex flex-col w-full mt-8 mb-8">
        <input
          className="input input-bordered mb-4 text-lg"
          placeholder="First name"
          ref={firstNameRef}
        />
        <input
          className="input input-bordered mb-4 text-lg"
          placeholder="Last name"
          ref={lastNameRef}
        />
        <input
          className="input input-bordered mb-4 text-lg"
          placeholder="Phone number"
          type="number"
          ref={phoneNumRef}
          onKeyDown={(event) => {
            if (
              !/[0-9]/.test(event.key) &&
              event.key !== "Delete" &&
              event.key !== "Backspace"
            ) {
              event.preventDefault();
            }
          }}
        />
      </div>
      <button className="btn btn-primary" onClick={handleSubmit}>
        Let's go!
      </button>
    </div>
  );
};

export default PersonalInfo;
