import { ChevronLeft } from "lucide-react";

function BackBtn({ handleClick }: { handleClick: () => void }) {
  return (
    <div
      className="flex flex-row w-auto items-center gap-1 mb-4 cursor-pointer"
      onClick={handleClick}
    >
      <ChevronLeft size={20} />
      <p className="text-base">Back</p>
    </div>
  );
}

export default BackBtn;
