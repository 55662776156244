import Logo from "./Logo.png";

function App() {
  return (
    <div className="bg-white flex justify-center h-full w-full">
      <div className="max-w-sm h-full">
        <img src={Logo} alt="The Wedding Game logo" />
        <div className="mb-5">
          <h2 className="text-4xl mb-2">What is this?</h2>
          <div className="space-y-3">
            <p>
              The Wedding Game is exactly as it sounds. It's a fun game where
              your wedding guests can guess what is going to happen on your big
              day. Think: will the groom cry? what will the first dance song be?
              will they smash cake in each other's faces?
            </p>
            <p>
              Except, it's even more fun than that.{" "}
              <span className="font-bold">Each guest places a wager </span>
              where they pick the amount 💰 they want to place in the pot for
              the wedding.
            </p>
            <p>
              The absolute best part of this? As the happy couple,{" "}
              <span className="font-bold">
                you get to keep it all (e.g. honeymoon fund)
              </span>{" "}
              and choose what to split with the winner (e.g. 50%). Plus,
              everyone has fun. That's a win-win-win.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-4xl mb-2">How does it work?</h2>
          <p className="pb-20">
            We're in testing mode right now so technically it doesn't fully work
            for the public. Coming soon...
          </p>
        </div>
      </div>
    </div>
  );
}

export default App;
