import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { db } from "../../../../firebase/db";
import { PublicEventContextI } from "../../../../types/types";

function VenmoRedirect() {
  const { betShortId, eventId } = useParams();
  const [loading, setLoading] = useState(false);
  const { venmoRedirectUrl, setVenmoRedirectUrl } =
    useOutletContext<PublicEventContextI>();
  let navigate = useNavigate();

  useEffect(() => {
    const getBetInfo = async () => {
      setLoading(true);
      if (betShortId && eventId) {
        try {
          const data = await db.bets.getByShortId(betShortId, eventId);
          if (data) {
            setVenmoRedirectUrl(data.venmoRedirectUrl);
          }
        } catch (error) {
          alert("Uh oh. We're so sorry. Unable to find data.");
        }
      }

      setLoading(false);
    };

    getBetInfo();
  }, [betShortId, eventId, setVenmoRedirectUrl]);

  useEffect(() => {
    if (venmoRedirectUrl) {
      navigate(`/event/${eventId}/venmo`);
    }
  }, [venmoRedirectUrl, eventId, navigate]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return <p>Redirecting...</p>;
}

export default VenmoRedirect;
