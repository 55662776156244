import { useOutletContext } from "react-router-dom";
import { PublicEventContextI } from "../../../../types/types";

const BetAmounts = () => {
  const { betAmount, setBetAmount } = useOutletContext<PublicEventContextI>();
  const handleBetUpdate = (amount: string) => {
    setBetAmount(amount);
  };

  return (
    <div className="input-group self-center justify-center mt-7 mb-7">
      <button
        className={`btn btn-circle mr-2 hover:bg-secondary active:bg-secondary ${
          betAmount === "10" ? "bg-secondary" : "bg-black"
        }`}
        onClick={() => handleBetUpdate("10")}
      >
        $10
      </button>
      <button
        className={`btn btn-circle mr-2 hover:bg-secondary active:bg-secondary ${
          betAmount === "25" ? "bg-secondary" : "bg-black"
        }`}
        onClick={() => handleBetUpdate("25")}
      >
        $25
      </button>
      <button
        className={`btn btn-circle mr-2 hover:bg-secondary active:bg-secondary ${
          betAmount === "50" ? "bg-secondary" : "bg-black"
        }`}
        onClick={() => handleBetUpdate("50")}
      >
        $50
      </button>
      <button
        className={`btn btn-circle mr-2 hover:bg-secondary active:bg-secondary ${
          betAmount === "100" ? "bg-secondary" : "bg-black"
        }`}
        onClick={() => handleBetUpdate("100")}
      >
        $100
      </button>
    </div>
  );
};

export default BetAmounts;
