import { useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import { PublicEventContextI } from "../../../../types/types";
import VenmoLogo from "../../../../assets/venmo-logo.png";
import BackBtn from "../../../../components/BackBtn";

const HowItWorks = () => {
  const { event, setStep } = useOutletContext<PublicEventContextI>();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="flex flex-col p-8 h-full max-w-sm">
      <BackBtn handleClick={() => setStep("welcome")} />
      <h1 className="text-4xl">How It Works</h1>
      <p className="mt-5 text-lg">{event.welcomeMsg}</p>
      <div className="mt-5">
        <h1 className="text-3xl mb-3">Steps</h1>
        <div className="flex items-center mb-2">
          <button className="btn btn-circle">1</button>
          <p className="ml-2 text-lg">Select the amount you want to give</p>
        </div>
        <div className="flex items-center mb-2">
          <button className="btn btn-circle">2</button>
          <p className="ml-2 text-lg">Pick your answers</p>
        </div>
        <div className="flex items-center mb-2">
          <button className="btn btn-circle">3</button>
          {event.paymentType === "venmo" ? (
            <p className="ml-2 text-lg flex items-center">
              Place your bet via{" "}
              <img
                src={VenmoLogo}
                alt="Venmo logo"
                className="h-4 inline-block ml-2"
              />
            </p>
          ) : (
            <p className="ml-2 text-lg">Place your bet</p>
          )}
        </div>
      </div>
      <button
        className="btn btn-primary self-center w-2/3 mt-5"
        onClick={() => setStep("betAmount")}
      >
        Get started
      </button>
    </div>
  );
};

export default HowItWorks;
