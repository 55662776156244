import { useOutletContext } from "react-router-dom";
import { PublicEventContextI } from "../../../types/types";
import Welcome from "./Welcome";
import HowItWorks from "./HowItWorks";
import PersonalInfo from "./PersonalInfo";
import BetSlipEntry from "./BetSlipEntry";
import BetAmount from "./BetAmount";
import VenmoPayment from "./BetSlipVenmoPayment";

const PublicEvent = () => {
  const { event, step } = useOutletContext<PublicEventContextI>();
  if (event && step) {
    return (
      <div className="h-full flex flex-col items-center flex-grow">
        {step === "welcome" && <Welcome />}
        {step === "howItWorks" && <HowItWorks />}
        {step === "betAmount" && <BetAmount />}
        {step === "personalInfo" && <PersonalInfo />}
        {step === "betSlipEntry" && <BetSlipEntry />}
        {step === "betSlipVenomPayment" && <VenmoPayment />}
      </div>
    );
  }

  return <h2 className="h-full flex flex-col items-center">Loading...</h2>;
};

export default PublicEvent;
