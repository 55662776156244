import { useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import { PublicEventContextI } from "../../../../types/types";
import BetAmounts from "../BetSlipEntry/BetAmounts";
import BackBtn from "../../../../components/BackBtn";

const BetAmount = () => {
  const { event, setStep } = useOutletContext<PublicEventContextI>();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="flex flex-col p-8 max-w-sm">
      <BackBtn handleClick={() => setStep("howItWorks")} />
      <h4 className="text-4xl mb-5">Pick your wager amount</h4>
      <p className="text-lg">
        Where does the money go? It goes into the wedding pool where{" "}
        <span className="font-semibold">
          the winner receives {event.winningsSplitPercent}%
        </span>{" "}
        and the remaining amount goes towards our honeymoon fund. That's what
        you call a win-win! Don't be shy now.
      </p>
      <BetAmounts />
      <button
        className="btn btn-primary self-center w-2/3 mt-5"
        onClick={() => setStep("personalInfo")}
      >
        Continue
      </button>
    </div>
  );
};

export default BetAmount;
