import { useEffect, useState } from "react";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import { db } from "../../../../firebase/db";
import { BetItemI, EventAnswerI } from "../../../../types/types";
import "./styles.css";
interface BetSlipItemProps {
  item: BetItemI | null;
  betIndex: number;
  updateBetItem: (itemId: string, answer: any, answerDbId?: string) => void;
  previousQuestion: () => void;
  type?: "regular" | "admin";
  isAdminView?: boolean;
  eventId?: string | undefined;
}

const BetSlipItem = ({
  item,
  betIndex,
  updateBetItem,
  previousQuestion,
  type = "regular",
  isAdminView = false,
  eventId,
}: BetSlipItemProps) => {
  const [answer, setAnswer] = useState<string | number>("");
  const [answerDbData, setAnswerDbData] = useState<EventAnswerI | null>();
  const [inProp, setInProp] = useState(false);

  useEffect(() => {
    const getAnswerData = async () => {
      const answerData = await db.answers.getByBetItemId(
        eventId as string,
        item?.id as string
      );
      if (answerData) {
        setAnswer(answerData?.answer);
        setAnswerDbData(answerData);
      }
    };

    if (!isAdminView) {
      // we want to reset the answer when a user is entering a bet
      // w/o this it was defaulting the previous answer for the next question
      // this is because we use the same component but just pass in a different item
      // TODO: this should be fixed so we can show the "current" answer
      setAnswer("");
    }

    if (item && eventId && isAdminView) {
      getAnswerData();
    }

    if (betIndex > 0 && !isAdminView) {
      setInProp((prevVal) => !prevVal);
    }
  }, [item, betIndex, isAdminView, eventId]);

  const updateAnswer = (value: string | number) => {
    setAnswer(value);
  };

  const handleUpdateBetItem = () => {
    if (!item) return;

    if (!answer) {
      alert("Select an answer");
    } else {
      updateBetItem(item.id, answer, answerDbData?.id);
    }
  };

  if (item) {
    const nonAnswerTypes = [null, undefined, ""];
    const hasAnswer =
      isAdminView && !nonAnswerTypes.includes(answerDbData?.answer);

    return (
      <SwitchTransition mode="out-in">
        <CSSTransition
          // @ts-ignore
          key={inProp}
          addEndListener={(node, done) => {
            node.addEventListener("transitionend", done, false);
          }}
          classNames="betItem"
        >
          {(state) => (
            <div
              className={`flex flex-col h-full rounded-3xl p-4 mb-3 w-full max-w-sm ${
                hasAnswer ? "bg-slate-400" : "bg-secondary"
              }`}
            >
              <div>
                <p className="text-sm text-white">Select an answer</p>
              </div>
              <h4 className="text-2xl text-neutral-content mb-8 mt-4">
                {item.title}
              </h4>
              {item.type === "yesno" && (
                <div>
                  <div
                    className="form-control items-start justify-center bg-neutral rounded-lg h-3/6"
                    onClick={() => updateAnswer("Yes")}
                  >
                    <label className="label cursor-pointer ml-3">
                      <input
                        type="radio"
                        name={`ww-radio-${item.title}`}
                        className="radio border-accent border-2 checked:bg-accent mr-2"
                        value={answer}
                        onChange={() => updateAnswer("Yes")}
                        checked={answer === "Yes"}
                      />
                      <span className="label-text text-white text-lg">Yes</span>
                    </label>
                  </div>
                  <div
                    className="form-control items-start justify-center bg-neutral rounded-lg mt-3 h-3/6"
                    onClick={() => updateAnswer("No")}
                  >
                    <label className="label cursor-pointer ml-3">
                      <input
                        type="radio"
                        name={`ww-radio-${item.title}`}
                        className="radio border-accent border-2 checked:bg-accent mr-2"
                        value={answer}
                        onChange={() => updateAnswer("No")}
                        checked={answer === "No"}
                      />
                      <span className="label-text text-white text-lg">No</span>
                    </label>
                  </div>
                </div>
              )}
              {item.type === "number" && (
                <div>
                  <input
                    type="number"
                    value={answer}
                    onChange={(e) => updateAnswer(e.target.value)}
                  />
                </div>
              )}
              {item.type === "dropdown" && item.options?.length && (
                <select
                  className="select select-bordered w-full max-w-xs"
                  value={answer}
                  onChange={(e) => updateAnswer(e.target.value)}
                >
                  <option disabled value="">
                    Select an answer
                  </option>
                  {item.options?.map((option, index) => (
                    <option key={index}>{option}</option>
                  ))}
                </select>
              )}
              {type === "regular" && (
                <div className="mt-auto w-full">
                  <button
                    className="btn btn-ghost text-neutral-content w-1/2"
                    onClick={previousQuestion}
                  >
                    Back
                  </button>
                  <button
                    className="btn bg-white text-primary border-white w-1/2"
                    onClick={handleUpdateBetItem}
                  >
                    Next
                  </button>
                </div>
              )}
              {type === "admin" && (
                <button
                  className="btn btn-ghost text-white font-bold mt-3"
                  onClick={handleUpdateBetItem}
                >
                  {hasAnswer ? "Change Answer" : "Confirm Answer"}
                </button>
              )}
            </div>
          )}
        </CSSTransition>
      </SwitchTransition>
    );
  }
  return null;
};

export default BetSlipItem;
