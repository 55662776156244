import { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useParams } from "react-router-dom";
import { db } from "../../firebase/db";
import { EventI, BetSlipEntryStepT, PersonalInfoI } from "../../types/types";

const PublicRoute = () => {
  const params = useParams();
  let eventId = params.eventId;

  const [event, setEvent] = useState<EventI>();
  const [personalInfo, setPersonalInfo] = useState<PersonalInfoI>();
  const [step, setStep] = useState<BetSlipEntryStepT>("welcome");
  const [betAmount, setBetAmount] = useState("25");
  const [betShortId, setBetShortId] = useState("");
  const [venmoRedirectUrl, setVenmoRedirectUrl] = useState("");

  useEffect(() => {
    const getEvent = async () => {
      if (eventId) {
        const event = await db.events.get(eventId);

        if (event) {
          setEvent({ ...event, id: event.id });
        }
      }
    };

    getEvent();
  }, [eventId]);

  return (
    <div className="btw-PublicRoute h-full">
      <Outlet
        context={{
          event,
          setEvent,
          step,
          setStep,
          personalInfo,
          setPersonalInfo,
          betAmount,
          setBetAmount,
          betShortId,
          setBetShortId,
          venmoRedirectUrl,
          setVenmoRedirectUrl,
        }}
      />
    </div>
  );
};

export default PublicRoute;
