import { Outlet } from "react-router-dom";

const AdminRoute = () => {
  return (
    <div className="btw-AdminRoute">
      <Outlet />
    </div>
  );
};

export default AdminRoute;
